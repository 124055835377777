<template>
  <history-list-card
    :title="title"
    :items="listItems"
    :loading="loading"
    :no-more-data="noMoreData"
    :show-shape="false"
    :when-download="handleDownload"
    @click:back="handleBack"
    @infinite="loadMore"
    @onRangeChanged="handleRangeChanged"
  />
</template>

<script>
import { useNotifications } from '@/modules/notifications/compositions/notifications';
import { onMounted, toRefs } from '@vue/composition-api';
import downloadBlob from '@/utils/downloadBlob';
import useMedia from '@/compositions/media';

export default {
  name: 'HistoryNotificatoinList',
  props: {
    objectId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const {
      listItems,
      load,
      loadMore,
      fromDate,
      toDate,
      noMoreData,
      loading
    } = useNotifications(props.objectId);
    const { getNotificationsHistory } = useMedia();
    const handleBack = () => {
      emit('stack:back');
    };

    const handleRangeChanged = (from, to) => {
      console.log(from, to);
      fromDate.value = from;

      if (toDate) {
        toDate.value = to;
      }
    };

    const handleDownload = async range => {
      const data = {
        ids: [props.objectId],
        from: `${range.from} 00:00`,
        to: `${range.to} 23:59`
      };
      await getNotificationsHistory(data)
        .then(res => {
          downloadBlob(
            res.data,
            `PixelMonitor_NotificationsHistory_${range.from}-${range.to}.csv`,
            res.data.type
          );
        })
        .catch(() => {})
        .finally(() => {});
    };

    onMounted(() => load());

    return {
      title: 'Notifications history',
      listItems,
      handleBack,
      loadMore,
      handleRangeChanged,
      noMoreData,
      loading,
      handleDownload
    };
  }
};
</script>

<style></style>
